import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ProductStock = ({ stock, currentUser }) => {
  if (stock && !currentUser)
    return <FontAwesomeIcon icon={faCheck}/>
  else if (!currentUser)
    return <FontAwesomeIcon icon={faTimes}/>
  return stock
}

export default ProductStock