import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Element } from 'react-bulma-components'
import Rating from 'react-rating'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarO } from '@fortawesome/free-regular-svg-icons'

const AttemptVote = ({ product, currentUser, vote }) => {
  const currentVote = product && product.kind === 'attempt' && currentUser &&
    product.votes.find(v => v.user === currentUser._id)
  return (
    <>
      <Element renderAs='p' textWeight='bold' mb={0}>Votre note</Element>
      <Rating
        initialRating={ currentVote && currentVote.score }
        emptySymbol={ <FontAwesomeIcon icon={faStarO} color='orange' /> }
        fullSymbol={ <FontAwesomeIcon icon={faStar} color='orange' /> }
        onChange={vote}
      />
    </>
  )
}

export default AttemptVote