import { faCartPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Form, Icon } from 'react-bulma-components'

const ProductQuantitySelector = ({ basket, productId, onChange }) => {
  const basketItem = React.useMemo(() =>
    basket && basket.find(v => v.item === productId), [basket]
  )
  const [quantity, setQuantity] = React.useState(basketItem ? basketItem.quantity : 1)

  return (
    <>
      <Form.Control onClick={ (e) => e.stopPropagation() }>
        <Form.Input
          type='number'
          style={{ width: '4em' }}
          size='small'
          defaultValue={quantity}
          onChange={(e) => {
            setQuantity(e.target.value)
          }}
        />
      </Form.Control>
      <Form.Control>
        <Button
          color='primary'
          size='small'
          outlined
          onClick={ () => {
            onChange({
              item: productId,
              quantity
            }) }}
          disabled={ basketItem && basketItem.quantity === quantity }
        >
          <Icon>
            <FontAwesomeIcon icon={faCartPlus} />
          </Icon>
        </Button>
      </Form.Control>
    </>
  )
}

export default ProductQuantitySelector